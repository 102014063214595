a.demo-card-link {
    text-decoration: none;
}

.demo-card {
    width: 200px;
    height: 240px;
    background-color: #1D201F;
    text-align: center;
    border-radius: 20px;
    padding-top: 10px;
    box-shadow: 5px 5px 0 #FF0057;
    display: inline-block;
    margin: 20px;
}

.demo-card:hover {
    box-shadow: 5px 5px 0 #FABC2A;
    cursor: pointer;
}

.demo-image {
    width: 90%;
    margin: auto;
    height: 180px;
    background-color: #F2EDEB;
    border-radius: 20px;
    position: relative;
}

.demo-image > img {
    width: 95%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.demo-title {
    width: 90%;
    margin: auto;
    text-align: center;
}

.demo-title > h1 {
    color: #F2EDEB;
    line-height: 18px;
    font-size: 18pt;
}