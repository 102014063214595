.selected-game {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 10px;
}
.selected-game:hover {
    cursor: pointer;
    background-color: #ff005553;
}
.selected-videogames-table {
    width: 100%;
    height: 30%;
}
.selected-videogames-list {
    height: 80%;
    min-height: 80%;
    max-height: 80%;
    overflow-y: auto;
    border: solid #FF0057 1px;
}
.table-title {
    height: 20%;
    display: flex;
    justify-content: center;
    padding: 5px;
    font-weight: 500;
    background: #FF0057;
}
.item-title  {
    width: 95%;
    display: flex;
    justify-content: start;
    align-items: center;
    text-align: left;
}
.item-remove {
    z-index: 800;
    width: 5%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.completed-container {
    font-size: 13pt;
}
.completed.completed-container {
    color: #31ca0b;
    font-size: 13pt;
}
