 .overlay {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    top: 10%;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.7);
    transition: opacity 500ms;
  }
  .popup {
    display: flex;
    flex-direction: column;
    margin: 70px auto;
    padding: 20px;
    background: #1D201F;
    border-radius: 5px;
    width: 75%;
    height: fit-content;
    position: relative;
    transition: all 5s ease-in-out;
    font-size: 75%;
  }

  .popup h2 {
    margin-top: 0;
  }
  .popup .close {
    position: absolute;
    top: 0;
    right: 30px;
    transition: all 200ms;
    font-size: 30px;
    font-weight: bold;
    text-decoration: none;
  }
  .popup .close:hover {
    cursor: pointer;
    color: #FABC2A;
  }

  
  @media screen and (max-width: 700px) {
    .popup {
      width: 70%;
    }
  }
  