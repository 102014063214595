html {
  margin: 0;
  font-family: 'Mukta', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  color: rgb(242, 237, 235);
  background-color: rgb(29, 32, 31);
  background: url('./img/landing_background.png') no-repeat no-repeat center fixed;
  background-size: cover;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
