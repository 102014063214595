.games-search-bar {
    width: 100%;
    height: 30%;
    display: flex;
    flex-direction: column;
}

.response-videogames {
    background: #F2EDEB;
    color: #1D201F;
    padding: 5px 10px 0 25px;
    overflow-y: auto;
    width: auto;
    height: 80%;
    max-height: 80%;
    min-height: 80%;
}

input.input-videogames {
    width: 100%;
    margin: 0;
    margin-bottom: 1px;
    padding: 5px 10px;
    padding-left: 25px;
    background: url("https://static.thenounproject.com/png/101791-200.png") no-repeat left #F2EDEB;
    background-size: 20px;
}
.input-searchbar {
    width: 100%;
    height: auto;
    display: flex;
}
.game-item {
    border-bottom: solid #1d201f37 1px;
    text-align: left;
}

.game-item:hover {
    background: #FABC2A;
    cursor: pointer;
}
@media screen and (max-width: 1200px) {
    .games-search-bar {
        width: 100%;
        height: 30%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .response-videogames {
        background: #F2EDEB;
        color: #1D201F;
        padding: 5px 10px 0 25px;
        overflow-y: auto;
        width: auto;
        height: 80%;
        max-height: 80%;
        min-height: 80%;
    }
}