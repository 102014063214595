
.centered-overlay {
    visibility: hidden;
    opacity: 0;
    position: fixed;
    top: -5%;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.7);
    transition: opacity 500ms;
    overflow: hidden;
    display: flex;
    align-items: center;
}

.news-container {
    height: 100%;
    width: 35%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}
.skip-button {
    width: auto;
    cursor: pointer;
    right: 0;
    top:0;
    position: absolute;
}
.skip-button-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: fixed;
    top: 5%;
    width: 100%;
    height: 9%;
    right: 0;
    z-index: 900;
    font-size: 100%;
}
.title {
    margin-bottom: 5%;
}
.yellow-word {
    color: #FABC2A;
}
.news-body {
    text-align: center;
}
.news-info {
    width: 100%;
    font-size: 100%;
    height: fit-content;
}
@media screen and (max-width: 1000px) {
    .skip-button {
        font-size: 100%;
    }
    .skip-button-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: fixed;
        width: 100%;
        height: 9%;
        right: 0;
        z-index: 900;
    }
    .news-container {
        height: 100%;
        width: 35%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        font-size: 90%;
    }
    .news-info {
        width: 100%;
        height: fit-content;
        font-size: 90%;
    }
}

@media screen and (min-width: 1200px) {
    .skip-button {
        font-size: 100%;
    }
    .skip-button-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: fixed;
        width: 100%;
        height: 9%;
        right: 0;
        z-index: 900;
    }
    .news-container {
        height: 100%;
        width: 25%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        font-size: 90%;
    }
    .news-info {
        width: 100%;
        height: fit-content;
    }
}