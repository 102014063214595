.menu-items {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: calc(100% - 0px);
    max-height: calc(100% - 0px);
    width: 100%;
}
.menu-item-list {
    display: flex;
    align-items: center;
    list-style: none;
    padding: 0;
    height: 100%;
    margin: 0;
}

.menu-item-list .main-item {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    height: 100%;
    font-size: 14pt;
    color: #F2EDEB;
    text-decoration: none;
    margin: 5px;
    margin-left: 15px;
    margin-right: 15px;
    padding: 10px;
}
.menu-item-list .main-item:hover {
    box-shadow: 3px 3px 0 #FF0057;
    border: 1px;
    border-radius: 8px;
}

.menu-item-list .item {
    font-size: 14pt;
    color: #F2EDEB;
    text-decoration: none;
    margin: 5px;
    margin-left: 15px;
    margin-right: 15px;
}


.menu-item-list .item:hover {
    color: #FABC2A;
}
.menu-item-list .menu-divider{
    border-left: 2px solid #FF0057;
    border-radius: 5px;
    height: calc(100% - 0px);
}
.quick-links {
    display: flex;
    justify-content: space-around;
    align-items: end;
    height: calc(100% - 0px);
    max-height: calc(100% - 0px);
}

.collapsable-list {
    width: 100%;
    height: 100%;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: right;
}

a.item {
    padding: 5%;
}

@media screen and (max-width: 768px) {
    .menu-items{
      display: none;
    }
}

.link-notification {
    height: 6px;
    width: 6px;
    background-color: #FABC2A;
    border-radius: 50%;
    border: 1px solid white;
    display: flex;
    align-self: flex-start;
    justify-self: right;
    position: relative;
}
