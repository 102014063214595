.email-verified-successfully{
    border-radius: 15px;
    background-color: #1D201F;
    padding: 1.5%;
}

.bold-highlight{
    font-weight: bold;
}

.advertise-container{
    margin: auto;
    width: 40%;
    min-width: 320px;
    margin-top: 2rem;
}
    
.email-verified-problem{
    border-radius: 15px;
    border:2px solid red;
    background-color: #1D201F;
    padding: 1.5%;
}

.form-container {
    margin-top: 2rem;
}