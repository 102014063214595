.wallet-component {
    width: 100%;
}

.help-text-wallet-rigth{
    
    top: 0;
}

.help-text-wallet-left{

    top: 0;
}