.column-1 {
    width: 20%;
    min-width: 280px;
    max-width: 350px;
}

.column-2 {
    text-align: left;
    padding: 5px 20px 30px 30px;
    background-color: #1D201F;
    border-radius: 10px;
    width: 60%;
    margin-right: 5%;
    margin-left: 5%;
}

.test-container {
    width: 95%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.image-container {
    width: 90%;
    text-align: center;
    border-radius: 10px;
    background-color: #F2EDEB;
    padding: 5%;
}

.image-container > img {
    width: 100%;
    margin: auto;
}

.image-container-mobile {
    display: none;
}

.summary-description {
    width: 85%;
    display: inline-block;
    background-color: #1D201F;
    padding: 10px 20px 20px 20px;
    margin-top: 10px;
    border-radius: 10px;
    text-align: left;
}

.summary-description > .title-container {
    font-size: 24pt;
    font-weight: bold;
    margin-top: 10px;
}

.summary-description > .title-container > .title-2 {
    font-size: 18pt;
}

.error-message {
    background-color: red;
    width: 90%;
    position: absolute;
    bottom: -10px;
    font-weight: bold;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
}

.text-description-container {
    background-color: #1D201F;
    border-radius: 10px;
    padding: 20px;
    margin: 10px 0 50px 0;
    min-height: 540px;
}

.description-container {
    position: relative;
}

.start-test-button {
    margin: auto;
    margin-top: 40px;
    text-align: center;
    max-width: 300px;
}

.description-text {
    margin-top: 30px;
    text-align: left;
    margin: 40px 10px 0 20px;
}

.form {
    margin-top: 50px;
}

.form > .question > textarea {
    width: 100%;
}

.form > .question > .slider-container {
    max-width: 350px;
}

.test-completed {
    margin-top: 50px;
}


.range-value {
    position: relative;
    display: block;
    font-size: 24pt;
    font-weight: bold;
    position: relative;
    bottom: 5px;
    margin-right: 10px;
    width: 30px;
}

.slider-container {
    display: flex;
}

input[type=range] {
    height: 25px;
    -webkit-appearance: none;
    margin: 10px 0;
    width: 100%;
    background: inherit;
  }
  input[type=range]:focus {
    outline: none;
  }
  input[type=range]::-webkit-slider-runnable-track {
    width: 100%;
    height: 5px;
    cursor: pointer;
    animate: 0.2s;
    box-shadow: 0px 0px 0px #000000;
    background: #2497E3;
    border-radius: 1px;
    border: 0px solid #000000;
  }
  input[type=range]::-webkit-slider-thumb {
    box-shadow: 0px 0px 0px #000000;
    border: 1px solid #2497E3;
    height: 18px;
    width: 18px;
    border-radius: 25px;
    background: #A1D0FF;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -7px;
  }
  input[type=range]:focus::-webkit-slider-runnable-track {
    background: #2497E3;
  }
  input[type=range]::-moz-range-track {
    width: 100%;
    height: 5px;
    cursor: pointer;
    animate: 0.2s;
    box-shadow: 0px 0px 0px #000000;
    background: #2497E3;
    border-radius: 1px;
    border: 0px solid #000000;
  }
  input[type=range]::-moz-range-thumb {
    box-shadow: 0px 0px 0px #000000;
    border: 1px solid #2497E3;
    height: 18px;
    width: 18px;
    border-radius: 25px;
    background: #A1D0FF;
    cursor: pointer;
  }
  input[type=range]::-ms-track {
    width: 100%;
    height: 5px;
    cursor: pointer;
    animate: 0.2s;
    background: transparent;
    border-color: transparent;
    color: transparent;
  }
  input[type=range]::-ms-fill-lower {
    background: #2497E3;
    border: 0px solid #000000;
    border-radius: 2px;
    box-shadow: 0px 0px 0px #000000;
  }
  input[type=range]::-ms-fill-upper {
    background: #2497E3;
    border: 0px solid #000000;
    border-radius: 2px;
    box-shadow: 0px 0px 0px #000000;
  }
  input[type=range]::-ms-thumb {
    margin-top: 1px;
    box-shadow: 0px 0px 0px #000000;
    border: 1px solid #2497E3;
    height: 18px;
    width: 18px;
    border-radius: 25px;
    background: #A1D0FF;
    cursor: pointer;
  }
  input[type=range]:focus::-ms-fill-lower {
    background: #2497E3;
  }
  input[type=range]:focus::-ms-fill-upper {
    background: #2497E3;
  }

.aclaratory {
    font-size: 10pt;
}

@media screen and (max-width: 1344px) {
    .summary-description > .title-container {
        font-size: 18pt;
        font-weight: bold;
    }

    .summary-description > .title-container > .title-2 {
        font-size: 14pt;
    }
}

@media screen and (max-width: 1024px) {
    .column-1 {
        max-width: 200px;
    }
    .column-2 {
        max-width: 500px;
    }

    .text-description-container {
        min-height: 550px;
    }

    .description-text {
        font-size: 11pt;
        margin-top: 20px;
    }
    
}

@media screen and (max-width: 768px) {
    .test-container {
        padding-top: 0;
    }

    .image-container {
        display: none;
    }

    .image-container-mobile {
        display: block;
        width: 80%;
        margin: auto;
        text-align: center;
        border: 10px solid #1D201F;
        border-radius: 10px;
        background-color: #F2EDEB;
        padding: 5%;
    }

    .column-1 {
        width: 100%;
        max-width: 100%;
        vertical-align: top;
    }

    .summary-description {
        width: 40%;
        margin: 5px;
        vertical-align: top;
        min-height: 285px;
    }

    .column-2 {
        width: 100%;
        max-width: 100%;
        margin: auto;
        min-height: 0;
    }

    .text-description-container {
        min-height: 0;
    }


    
    .image-container-mobile > img {
        width: 100%;
        margin: auto;
    }
    
}

@media screen and (max-width: 468px) {
    .test-container {
        padding: 0;
    }

    .summary-description {
        width: 85%;
    }

    .description-text {
        font-size: 10pt;
    }
}
