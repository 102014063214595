
.purchase-requirements{
    border: 1px solid;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    margin: 2%;
    padding: 2%;
    width: 95%;
    align-items: center;
}


.reward-purchase-info{
    width: 95%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.detail-image{
    width:100%;
    margin-bottom: 2%;
}
.main-reward-item-container{
    width: 100%;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
}

.reward-item-detail{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 90%;
}

.purchase-description{
    text-align: justify;
    text-justify: inter-word;
    max-height: 370px;
    overflow: auto;
    padding-right: 2%;
    width: 55%;
}

.redeem-reward-container{
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
}

.redeem-butt{
    margin:2%;
    width: 95%;
    font-size: 80%;
}

.redeem-butt:disabled{
    opacity: 0.45;
    cursor: not-allowed;
    pointer-events: none;
}

.problems-container {
    text-align: justify;
    text-justify: inter-word;
    color: #FABC2A;
    width: 95%;
    margin: 2%;
    padding: 2%;
}

.rewards-wallet-container {
    width: 95%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #FABC2A;
    border-radius: 5px;
    margin: 2%;
    padding: 2%;
}

.rewards-wallet{
    display: flex;
    width: 95%;
    flex-direction: row;
    justify-content: space-evenly;
}
.rewards-wallet-title{
    margin:0;
    font-weight: bolder;
}
.rewards-wallet-values {
    font-weight: bolder;
}
.rewards-wallet-labels{
    font-size: 100%;
}
hr.rounded-divider {
    width:100%;
    border-top: 2px solid #bbb;
    border-radius: 1px;
    margin: 2%;
}

@media screen and (max-width: 768px) {
    .purchase-description {
        text-align: justify;
        text-justify: inter-word;
        max-height: 370px;
        overflow: auto;
        padding-right: 2%;
        height: 80%;
        min-height: 170px;
        margin: 10px;
    }
    .redeem-reward-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .reward-item-detail {
        display: flex;
        justify-content: space-between;
        width: 90%;
        flex-direction: column;
        overflow-y: auto;
        max-height: 400px;
        padding-right: 20px;
        overflow-x: clip;
    }

    .purchase-description {
        text-align: justify;
        text-justify: inter-word;
        max-height: 370px;
        overflow: auto;
        padding-right: 2%;
        min-height: 170px;
        margin: 10px;
        width: 100%;
    }

    .reward-purchase-info {
        width: 100%;
    }
    .redeem-reward-container {
        width: 100%;
    }
    
}