.nav-bar {
    width: 98%;
    display: flex;
    height: fit-content;
    justify-content: space-between;
    align-items: center;
}

.header-img {
    width: 11rem;
    padding: 20px;
    margin: auto;
      margin-right: auto;
      margin-left: auto;
    margin-right: 20px;
    margin-left: 20px;
}