.profile-wallet {
    text-align: center;
    display: flex;
    flex-direction: row;
    padding: 32px 20px 32px 10px;
    background-color: #1D201F;
    position: relative;
    border-radius: 10px;
    margin-top: 20px;
    line-height: 50px;
    font-size: 32pt;
    margin-bottom: 20px;
}
.profile-wallet-values {
    font-size: 32pt;
    font-weight: bold;
    line-height: 50px;
}

.profile-wallet-labels {
    font-size: 16pt;
    font-weight: bold;
    margin-top: -10px;
}

.user-profile-container {
    width: 20%;
    min-width: 280px;
    max-width: 350px;
}

.user-profile {
    text-align: left;
    padding: 20px;
    background-color: #1D201F;
    position: relative;
    border-radius: 10px;
    min-height: 420px;
}

.user-profile-fields {
    width: 90%;
    min-height: 100%;
    margin: auto;
    margin-top: 10px;
    margin-bottom: 10px;
}

.edit-button {
    display: flex;
    align-items: stretch;
    justify-content: flex-end;
    width: 90%;
    margin: auto;
    margin-top: 10px;
    margin-bottom: 10px;
}

.edit-button > button {
    font-size: 10pt;
    font-weight: bold;
    height: 32px;
    right: 0;
    width: 120px;
    margin: 0;
}

.edit-button > button.save {
    background-color: #229605;
    width: 90px;
}

.edit-button > button.save:hover {
    background-color: #74eb56;
}

.edit-button > button.cancel {
    background-color: #FF0057;
    left: 0;
    width: 90px;
    margin-right: 10px;
}

@media screen and (max-width: 1080px) {
    .user-profile-container {
        display: block;
        margin: auto;
        width: 100%;
        max-width: 100%;
        position: relative;
    }
}

@media screen and (min-width: 1680px) {
    .user-profile-container {
        max-width: 450px;
    }
}

@media screen and (min-width: 1920px) {
    .user-profile-container {
        max-width: 500px;
    }
}
