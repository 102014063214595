.helper-icon {
    width: 15px;
    height: 15px;
    line-height: 16px;
    border-radius: 100%;
    font-size: 8pt;
    color: #F2EDEB;
    border: 1px solid #F2EDEB;
    text-align: center;
    display: inline-block;
}

.helper-icon:hover{
    cursor: pointer;
}

.helper-text {
    line-height: 18px;
    font-size: 10pt;
    color: #1D201F;
    background-color: #F2EDEB;
    border: solid 1px #A9B1AF;
    border-radius: 5px;
    padding: 8px;
    z-index: 9999;
    overflow-x: visible;
    min-width: 150px;
    position: absolute;
    width: 25%;
}

.helper-text:hover{
    cursor: pointer;
}

.helper-container{
    display: contents;
    position: relative;
}