.App {
  text-align: center;
  color: rgb(242, 237, 235);
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.page-container{
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.header{
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background-color: #1D201F;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.page-content {
  width: 100%;
  padding-top: 125px;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  margin: auto;
}

.hidden {
  display: none;
}

.text-center {
  text-align: center !important;
  margin: auto !important;
}

.form-container {
  text-align: center;
  width: 30%;
  min-width: 320px;
  margin: 60px auto 20px auto;
  padding-top: 10px;
  padding-bottom: 30px;
  border-radius: 10px;
  background-color: rgb(29, 32, 31, 0.95);
  display: block;
}

.submit-button {
  max-width: 280px;
}

@media screen and (max-width: 720px) {
  .form-container{
    width: 100%;
  }
}

.error {
  border: solid 6px rgb(255, 0, 87);
  padding: 5px !important;
}

.error-text {
  font-size: 10pt;
  color: red;
  margin: 5px 15px 10px 36px;
  text-align: left;
}

input {
  width:80%;
  height: 30px;
  margin: 5px 10px 5px 10px;
  border-radius: 5px;
  padding: 5px 10px;
  border: none;
}

input.checkbox {
  width: 20px;
  margin: 0 10px 0 0;
  text-align: left;
  border-color: red;
}

.checkbox-container {
  text-align: left;
  margin: 10px auto 10px auto;
  font-size: 11pt;
  line-height: 20px;
  width: 80%;
}

.checkbox-container > .checkbox {
  display: inline-block;
}

.checkbox-container > .label {
  display: inline-block;
  max-width: 80%;
  height: 30%;
  
}

button {
  height: 50px;
  width: 86%;
  border-radius: 5px;
  border: none;
  color: #F2EDEB;
  background-color: #FF0057;
  font-family: inherit;
  font-weight: bold;
  font-size: 16pt;
  margin: 10px 10px 5px 10px;
}

button:hover {
  cursor: pointer;
  background-color: #FABC2A;
}

.switch-login {
  margin-top: 15px;
  font-size: 10pt;
}

.switch-login-link {
  color: #FABC2A;
  font-weight: bold;
  margin-left: 5px;
}

.switch-login-link:hover {
  color: #FF0057;
}

.color-yellow {
  color: #FABC2A !important
}

.color-red {
  color: #FF0057 !important
}

.color-white {
  color:#F2EDEB !important
}

.strong {
  font-weight: bold !important;
}

.no-text-decoration {
  text-decoration: none !important;
}

.container-g-recaptcha{
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  transform: scale(0.90);
  margin-top: 25px;
  margin-bottom: 25px;
}
.errors-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  margin-top: 5px;
  margin-bottom: 5px;
  width: 100%;
}