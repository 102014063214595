.success-text {
    color:#FABC2A;
    font-weight: bold;
}
.container-g-recaptcha{
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    transform:scale(0.90);
    margin-top: 5px;
    margin-bottom: 5px;
}
.errors-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    margin-top: 5px;
    margin-bottom: 5px;
    width: 100%;
}
.submit-button {
    margin-top: 0;
}