.collapsible-icon {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: end;
    justify-content: center;
    cursor: pointer;
}
.collapsible-icon .bar1, .bar2, .bar3 {
    height: 2px;
    width: 20px;
    background-color: #F2EDEB;
    margin: 2px 0;
}
.collapsible-list{
    position: absolute;
    background-color: #1D201F;
    width: 100%;
    margin-top: 5px ;
    border: 1px;
    border-radius: 5px;
    opacity: 0;
    visibility: hidden;
}
.change.collapsible-list{
    transition: 0.8s;
    opacity: 1;
    visibility: visible;
}

/* Rotate first bar */
.change .bar1 {
    transform: translate(0, 5px) rotate(-45deg);
    transition: 0.5s;
}

/* Fade out the second bar */
.change .bar2 {
    opacity: 0;
    transition: 0.5s;
}
  
/* Rotate last bar */
.change .bar3 {
    transition: 0.5s;
    transform: translate(0, -6px) rotate(45deg);
}
.desplegable-links{
    position: absolute;
    width: 15%;
    text-decoration: none;
    list-style: none;
}


.desplegable-links a{
    width: 100%;
    text-decoration: none;
    font-size: 14pt;
    border-radius: 5px;
    color: #F2EDEB;
    display: flex;
    justify-content: center;
}

.desplegable-links a:hover{
    color: #1D201F;
}

.desplegable-links .link{
    border-bottom: 2px solid;
}

.desplegable-links .link:hover{
    background-color: #FABC2A;
    color:#1D201F;
}