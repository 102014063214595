.rewards-container{
    display: flex;
    width: 95%;
    min-height: 420px;
    background-color: #1D201F;
    border-radius: 10px;
    flex-direction: column;
}
.demo-cards-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
}