.demo-list-container {
    text-align: left;
    padding: 5px 20px 30px 30px;
    background-color: #1D201F;
    border-radius: 10px;
    width: 60%;
    margin-right: 5%;
    margin-left: 5%;
}

.demo-list-container > .title {
    margin: auto;
    text-align: left;
}

.demo-list-container > .title > h1 {
    font-size: 18pt;
}