.overlay {
    visibility: hidden;
    opacity: 0;
    position: fixed;
    top: 10%;
    z-index: 100;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.7);
    transition: opacity 500ms;
}
.rewards-item{
    width: 30%;
    border: 2px solid #F2EDEB;
    border-radius: 5px;
    margin: 20px;
    background-color: #F2EDEB;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    justify-content: center;
    min-width: 150px;
    padding: 5px;
}
.rewards-item-img{
    margin: 10px;
    width: 90%;
}
.rewards-item-name{
    color: #1D201F;
    font-weight:bold;
    width: 100%;
}
.rewards-item:hover{
    border-color: #FABC2A;
    cursor: pointer;
    background-color: #FABC2A
}