.legal-text-container {
    width: 80%;
    max-width: 720px;
    margin: auto;
    border-radius: 10px;
    background-color: #F2EDEB;
    color: #1D201F;
    padding: 50px;
    text-align: justify;
}

.legal-text-container > h1.title {
    text-align: center;
    text-decoration: underline;
    font-size: 18pt;
    margin-bottom: 24px;
    font-weight: bold;
}

.legal-text-container > h2.title {
    text-align: left;
    font-size: 14pt;
    margin-bottom: 10px;
    margin-top: 20px;
    font-weight: bold;
    line-height: 20px;
}

.legal-text-container > h3.title {
    text-align: left;
    font-size: 12pt;
    margin-bottom: 5px;
    margin-top: 5px;
    font-weight: bold;
}

.legal-text-container > p.paragraph {
    text-align: justify;
    line-height: 20px;
    margin-bottom: 15px;
}

a {
    color: blue;
}

a:hover {
    color: blue;
}

p.paragraph {
    margin-bottom: 20px;
}

ol > li {
    margin-top: 20px;
}

table.legal-table {
    width: 95%;
    text-align: left;
}

.legal-table > thead > tr > th{
    background-color: grey;
    color: white;
    border: solid 1p black;
    padding-left: 10px;
}

.legal-table > tbody > tr > td {
    padding-left: 10px;
}