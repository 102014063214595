.profile-title-container {
    display: contents;
    position: relative;
    margin-bottom: 20px;
}
.custom-helptext {
    display: block;
    position: absolute;
    width: 20%;
    top: 10px;
    right: 0;
    z-index: 100;
}

.inside-profile-invitation-container {
    width:90%;
    margin: 8px auto 16px auto;
}

.profile-invitations-title {
    font-size: 16pt;
    text-align: left;
    width: 90%;
    margin: auto;
    margin-bottom: 10px;
}

.profile-invitations-container{
    text-align: center;
    display: flex;
    flex-direction: column;
    background-color: #1D201F;
    position: relative;
    border-radius: 10px;
    margin-top: 20px;
    padding: 20px;
}

.user-invitation-statistics{
    display: block;
    position: relative;
    width: 100%;
    margin-top: 10px;
    font-size: 10pt;
    text-align: center;
}


.user-invitation-clipboard {
    display: flex;
    justify-content: center;
    margin: auto;
    align-items: center;
}

.invite-text {
    border-radius: 5px 0px 0px 5px;
    margin: 0px;
    position: relative;
    width: 75%;
    height: 100%;
    font-size: inherit;
}

.invite-button {
    border-radius: 0px 5px 5px 0px;
    margin: 0px;
    position: relative;
    width: 20%;
    height: 100%;
    font-size: inherit;
}

.number-of-invitations-value {
    font-weight: bolder;
}