.restore-form {
    display: block;
}

.info-text {
    text-align: left;
    font-size: 90%;
    padding-left: 8%;
    padding-right: 8%;
    margin-bottom: 10px;
}
.info-text-center {
    text-align: center;
    font-size: 90%;
    padding-left: 8%;
    padding-right: 8%;
    margin-bottom: 10px;
}

.text-yellow-info{
    color: rgb(250, 188, 42); 
}

.passrestore-container{
    width: 50rem;
}