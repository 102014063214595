.summary-element {
    margin-bottom: 10px;
    position: relative;
}

.summary-element > .label {
    font-size: 10pt;
    position: relative;
}

.summary-element > .value {
    font-size: 16pt;
    line-height: 24px;
    font-weight: 600;
}

a.summary-element-link {
    color: #F2EDEB;
    text-decoration: underline;
}

a.summary-element-link:hover {
    color: #FABC2A;
}

.help-container {
    position: absolute;
    top: 15px;
    right: 0px;
    width: 100%;

}

.help-action {
    width: 15px;
    height: 15px;
    line-height: 16px;
    border-radius: 100%;
    font-size: 8pt;
    color: #F2EDEB;
    border: 1px solid #F2EDEB;
    text-align: center;
    position: absolute;
    right: 10px;
}

.help-text {
    line-height: 18px;
    font-size: 10pt;
    color: #1D201F;
    background-color: #F2EDEB;
    border: solid 1px #A9B1AF;
    border-radius: 5px;
    padding: 8px;
    z-index: 9999;
    overflow-x: visible;
    min-width: 150px;
}

@media screen and (max-width: 1280px) {
    .summary-element {
        margin-bottom: 5px;
    }
    .summary-element > .label {
        font-size: 8pt
    }
    
    .summary-element > .value {
        font-size: 12pt
    }    
}