.close-icon{
    height: 10px;
    width: 10px;
}
.close-icon .line1 {
    height: 2px;
    width: 100%;
    background-color: #F2EDEB;
    transform:translate(0px, 5px) rotate(-45deg);
}

.close-icon .line2 {
    height: 2px;
    width: 100%;
    background-color: #F2EDEB;
    transform: translate(0px, 3px) rotate(45deg);
}
