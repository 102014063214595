   .hexa-container{
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: fixed;
   }

  .hexagonpopup .close:hover {
    cursor: pointer;
    color: #FABC2A;
  }
  .hexagon {
    overflow: hidden;
    visibility: hidden;
    -webkit-transform: rotate(120deg);
       -moz-transform: rotate(120deg);
        -ms-transform: rotate(120deg);
         -o-transform: rotate(120deg);
            transform: rotate(120deg);
    }
    .hexagonin1 {
        overflow: hidden;
        height: 100%;
        -webkit-transform: rotate(-60deg);
           -moz-transform: rotate(-60deg);
            -ms-transform: rotate(-60deg);
             -o-transform: rotate(-60deg);
                transform: rotate(-60deg);
    }
    .hexagonin2 {
        height: 100%;
        background: #1D201F;
        visibility: visible;
        -webkit-transform: rotate(-60deg);
           -moz-transform: rotate(-60deg);
            -ms-transform: rotate(-60deg);
             -o-transform: rotate(-60deg);
                transform: rotate(-60deg);
         display: flex;
         flex-direction: column;
         align-items: center;
         justify-content: center;
    }

   .hexagon1 {
      width: 100%;
      height: 90%;
      position: absolute;
      z-index: 600;
    }
   .popup .close {
      position: absolute;
      top: 0;
      right: 30px;
      transition: all 200ms;
      font-size: 30px;
      font-weight: bold;
      text-decoration: none;
    }

    .outerhexagon{
      overflow: hidden;
      width: 100%;
      height: 100%;
      
      -webkit-transform: rotate(-60deg);
         -moz-transform: rotate(-60deg);
          -ms-transform: rotate(-60deg);
           -o-transform: rotate(-60deg);
              transform: rotate(-60deg);
    }
    .outerhexagon1 {
      width: 100%;
      height: 91%;
      position: absolute;
      z-index: 500;
   }

   .outerhexagonin1 {
      overflow: hidden;
      width: 100%;
      height: 100%;
      -webkit-transform: rotate(-60deg);
         -moz-transform: rotate(-60deg);
          -ms-transform: rotate(-60deg);
           -o-transform: rotate(-60deg);
              transform: rotate(-60deg);
  }
  .outerhexagonin2 {
      width: 100%;
      height: 100%;
      background: #FABC2A;
      visibility: visible;
      -webkit-transform: rotate(-60deg);
         -moz-transform: rotate(-60deg);
          -ms-transform: rotate(-60deg);
           -o-transform: rotate(-60deg);
              transform: rotate(-60deg);
       display: flex;
       flex-direction: column;
       align-items: center;
       justify-content: center;
  }
  @media screen and (max-width: 1000px) {
   .outerhexagon1 {
      width: 100%;
      height: 86%;
      position: absolute;
      z-index: 500;
   }
   .hexagon1 {
      width: 100%;
      height: 85%;
      position: absolute;
      z-index: 600;
    }
}