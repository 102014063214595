p {
    margin: 0;
}

.profile-value {
    font-size: 16pt;
    line-height: 30px;
}

.profile-label {
    font-size: 10pt;
}

.profile-field {
    margin: 8px auto 16px auto;
}

.profile-input {
    line-height: 32px;
    width: 100%;
    max-width: 450px;
    margin: auto;
    height: 20px;
    padding: 5px 10px !important;
    border-radius: 5px;
    border: none;
}

.profile-input.error {  
    background-color: #fa6464;
}

select.profile-input {
    height: 30px;
    width: 60%;
    max-width: 465px;
}
