.gamer-profile-form {
    width: 100%;
    height: 67%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}
.form-button-container {
    height: 20%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.confirmation-button {
    background-color: #229605;
    width: auto;
    font-size: 80%;
}
.confirmation-button:hover {
    background-color: #31ca0b;
}
.confirmation-button:disabled {
    background-color: #229605;
    opacity: 0.8;
}