.checkbox-container {
    position: relative;
}

.checkbox-container-2 {
    min-height: 75px;
}

.label {
    position: absolute;
    top: 6px;
}

.submit-button {
    margin-top: 0;
}

.username-bold {
    color: #FF0057;
}
.errors-container{
    font-size: 11pt;
    display: flex;
    justify-content: space-around;
    margin: auto;
    text-align: left;
}
